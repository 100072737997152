import React from "react";
import {
  BarChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Bar,
  ComposedChart,
  PieChart,
  Pie,
} from "recharts";

const Stats = (props) => {
  const { popularArrObj, unpopArrObj, arrObj, data } = props;

  const showStats = () => {
    const stats = document.getElementsByClassName("stats")[0];
    const statsWrapper = document.getElementsByClassName("stats-wrapper")[0];

    stats.style.display === "block"
      ? (stats.style.display = "none")
      : (stats.style.display = "block");

    stats.style.display === "block"
      ? (statsWrapper.style.border = "4px solid #2493f2")
      : (statsWrapper.style.border = "none");
  };

  return (
    <>
      <h2 onClick={showStats}>Stats</h2>
      <div className="stats-wrapper">
        <div className="stats">
          <p className="stats-description">
            Note: might need a bit of horizontal scrolling on mobile lads
          </p>
          <h3 className="stats-heading">Decade breakdown!</h3>
          <BarChart
            width={500}
            height={300}
            data={arrObj}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="2 2" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#60ee95" />
          </BarChart>
          <p className="stats-description">
            The songs broken down by the decade. Based on the album so songs
            taken from compilations might duke the stats a bit
          </p>
          <h3 className="stats-heading">Most popular tracks!</h3>
          <ComposedChart
            layout="vertical"
            width={500}
            height={1000}
            data={popularArrObj}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 50,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" scale="band" width={100} />
            <Tooltip />
            <Legend />
            <Bar dataKey="rating" barSize={20} fill="#ff007f" />
          </ComposedChart>
          <p className="stats-description">
            Based on the 'popularity' attribute on each track. Fleetwood looking
            good hey
          </p>
          <h3 className="stats-heading">Most obscure tracks!</h3>
          <ComposedChart
            layout="vertical"
            width={500}
            height={1000}
            data={unpopArrObj}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 50,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" scale="band" width={100} />
            <Tooltip />
            <Legend />
            <Bar dataKey="rating" barSize={20} fill="#ff007f" />
          </ComposedChart>
          <p className="stats-description">
            Based on the 'popularity' attribute on each track. Who be the biggst
            hipster!?
          </p>
          <h3 className="stats-heading">The Pie!</h3>
          <PieChart width={400} height={400}>
            <Pie
              dataKey="value"
              isAnimationActive={false}
              data={data}
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#0310ea"
              label
            />
            <Tooltip />
          </PieChart>
          <p className="stats-description">
            A breakdown of all the artists based on whether they start with
            'The' or not
          </p>
          <h3 className="stats-heading">Artist Info!</h3>
          <p className="stats-description">The award for most apps goes to..</p>
          <p>
            <span role="img" aria-label="drums">
              🥁{" "}
            </span>
          </p>
          <p className="stats-description">Oasis with 5</p>
          <p className="stats-description">
            (but yes, a New Order (4) and Joy Division (3) combination would
            come out on top)
          </p>
          <h3 className="stats-heading">Song Info!</h3>
          <p className="stats-description">
            The Longest tune is how do you sleep?
          </p>
          <p className="stats-description">
            Whie the shortest tune is You Really Got Me - Mono Mix!
          </p>
          <h3 className="stats-heading">Playlist Info!</h3>
          <p className="stats-description">
            The total time is 9 Hours, 55 Minutes and 16 Seconds.
          </p>
          <p className="stats-description">
            Nearly 10 hours of pure playlist bliss{" "}
            <span role="img" aria-label="heart">
              ♥️
            </span>
          </p>
          <p className="stats-description">
            The shortest playlist is Route 1 Tunes (nice and direct hey)
          </p>
          <p className="stats-description">
            And the longest playlist is Eamo's 10 (bangin' on a bit wha)
          </p>
          <p className="stats-description">
            Shout out to In Memory of the Ten for being the most obscure
            playlist..
          </p>
          <p className="stats-description">
            And big congrats to Eamo's 10 for being the most popular playlist!
          </p>
        </div>
      </div>
    </>
  );
};

export default Stats;
