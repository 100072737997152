import React from "react";
import FamilyTree from "../FamilyTree";

import familyTreeJson from "../../data/mcdonagh.json";

export default function McDonagh() {
  return (
    <FamilyTree
      fam="McDonagh"
      linkFam="McTiernan"
      familyTreeJson={familyTreeJson}
    />
  );
}
