import React, { useEffect, useState } from "react";

import Playlist from "./components/Playlist";
import PlaylistBonus from "./components/PlaylistBonus";
import Stats from "./components/Stats";

import keithList from "./PlaylistData/keith.json";
import bootsList from "./PlaylistData/boots.json";
import odList from "./PlaylistData/od.json";
import goonList from "./PlaylistData/goon.json";
import tinmanList from "./PlaylistData/tinman.json";
import evList from "./PlaylistData/ev.json";
import davList from "./PlaylistData/dav.json";
import caseyList from "./PlaylistData/casey.json";
import dooliList from "./PlaylistData/dooli.json";
import eamoList from "./PlaylistData/eamo.json";
import aidoList from "./PlaylistData/aido.json";
import jtList from "./PlaylistData/jt.json";
import mattList from "./PlaylistData/matt.json";
import mmsList from "./PlaylistData/mms.json";

import "./Discs.scss";

const Discs = () => {
  const [keith, setKeith] = useState([]);

  const [boots, setBoots] = useState([]);

  const [od, setOd] = useState([]);

  const [goon, setGoon] = useState([]);

  const [tinman, setTinman] = useState([]);

  const [ev, setEv] = useState([]);

  const [dav, setDav] = useState([]);

  const [casey, setCasey] = useState([]);

  const [dooli, setDooli] = useState([]);

  const [eamo, setEamo] = useState([]);

  const [aido, setAido] = useState([]);

  const [jt, setJt] = useState([]);

  const [matt, setMatt] = useState([]);

  const [mms, setMms] = useState([]);

  const [keithVisible, setKeithVisible] = useState(false);

  const [bootsVisible, setBootsVisible] = useState(false);

  const [odVisible, setOdVisible] = useState(false);

  const [goonVisible, setGoonVisible] = useState(false);

  const [tinmanVisible, setSknnyVisible] = useState(false);

  const [evVisible, setEvVisible] = useState(false);

  const [davVisible, setDavVisible] = useState(false);

  const [caseyVisible, setCaseyVisible] = useState(false);

  const [dooliVisible, setDooliVisible] = useState(false);

  const [eamoVisible, setEamoVisible] = useState(false);

  const [aidoVisible, setAidoVisible] = useState(false);

  const [jtVisible, setJtVisible] = useState(false);

  const [mattVisible, setMattVisible] = useState(false);

  const [mmsVisible, setMmsVisible] = useState(false);

  const showKeith = () => {
    setKeithVisible(!keithVisible);
  };

  const showBoots = () => {
    setBootsVisible(!bootsVisible);
  };

  const showOd = () => {
    setOdVisible(!odVisible);
  };

  const showGoon = () => {
    setGoonVisible(!goonVisible);
  };

  const showTinman = () => {
    setSknnyVisible(!tinmanVisible);
  };

  const showEv = () => {
    setEvVisible(!evVisible);
  };

  const showDav = () => {
    setDavVisible(!davVisible);
  };

  const showCasey = () => {
    setCaseyVisible(!caseyVisible);
  };

  const showDooli = () => {
    setDooliVisible(!dooliVisible);
  };

  const showEamo = () => {
    setEamoVisible(!eamoVisible);
  };

  const showAido = () => {
    setAidoVisible(!aidoVisible);
  };

  const showJt = () => {
    setJtVisible(!jtVisible);
  };

  const showMatt = () => {
    setMattVisible(!mattVisible);
  };

  const showMms = () => {
    setMmsVisible(!mmsVisible);
  };

  useEffect(() => {
    document.title = "Discs";
    setKeith(keithList.tracks.items);
    setBoots(bootsList.tracks.items);
    setOd(odList.tracks.items);
    setGoon(goonList.tracks.items);
    setTinman(tinmanList.tracks.items);
    setEv(evList.tracks.items);
    setDav(davList.tracks.items);
    setCasey(caseyList.tracks.items);
    setDooli(dooliList.tracks.items);
    setEamo(eamoList.tracks.items);
    setAido(aidoList.tracks.items);
    setJt(jtList.tracks.items);
    setMatt(mattList.tracks.items);
    setMms(mmsList.tracks.items);
  }, []);

  const release_dates = [];

  const popularity = [];

  const artists = [];

  let totalTime = 0;

  let shortestTime = 10000000;

  let longestTime = 0;

  let shortestTrack = "";

  let longestTrack = "";

  const popPlaylist = [];

  let keithCount = 0;
  keith.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  keithCount = 0;
  boots.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  keithCount = 0;
  od.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  keithCount = 0;
  goon.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  keithCount = 0;
  tinman.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  keithCount = 0;
  ev.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  keithCount = 0;
  dav.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  keithCount = 0;
  casey.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  keithCount = 0;
  dooli.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  keithCount = 0;
  eamo.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  keithCount = 0;
  aido.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  keithCount = 0;
  jt.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  keithCount = 0;
  matt.forEach((item) => {
    const { track } = item;
    release_dates.push(track.album.release_date.split("-")[0].slice(0, 3));
    popularity.push([track.name, track.popularity]);
    artists.push(track.artists[0].name);
    totalTime += track.duration_ms;

    if (track.duration_ms < shortestTime) {
      shortestTime = track.duration_ms;
      shortestTrack = track.name;
    }

    if (track.duration_ms > longestTime) {
      longestTime = track.duration_ms;
      longestTrack = track.name;
    }

    keithCount += track.duration_ms;
  });

  popPlaylist.push(keithCount);

  let obj = {};
  let arrObj = [];
  release_dates.forEach((year) => {
    if (obj[year]) {
      obj[year]++;
    } else {
      obj[year] = 1;
    }
  });

  Object.keys(obj).forEach((year) => {
    const decade = `${year[year.length - 1]}0s`;
    arrObj.push({ name: decade, count: obj[year] });
  });

  for (let i = 0; i < popularity.length; i++) {
    for (let j = 0; j < popularity.length; j++) {
      if (popularity[i][1] >= popularity[j][1]) {
        let temp = popularity[j];
        popularity[j] = popularity[i];
        popularity[i] = temp;
      }
    }
  }

  if (popularity.length === 135) {
    popularity.splice(2, 1);
  }

  let popArrObj = [];

  popularity.forEach((item) => {
    const obj = { name: item[0].split(" - ")[0], rating: item[1] };
    if (item[1] > 0) {
      popArrObj.push(obj);
    }
  });

  const popularArrObj = popArrObj.slice(0, 10);

  const unpopArrObj = popArrObj.slice(-10);

  let theCount = 0;

  artists.forEach((name) => {
    if (name.split(" ")[0].includes("The")) {
      theCount++;
    }
  });

  const noTheCount = artists.length - theCount;

  const data = [
    { name: "The", value: theCount },
    { name: "No The", value: noTheCount },
  ];

  // Report Data

  //console.log(artists);

  // let artistCount = {};

  // artists.forEach((artist) => {
  //   if (artistCount[artist]) {
  //     artistCount[artist]++;
  //   } else {
  //     artistCount[artist] = 1;
  //   }
  // });

  //console.log(artistCount);

  //const vals = Object.keys(artistCount);

  //console.log(vals);

  // var result = Object.keys(artistCount).map((key) => [key, artistCount[key]]);

  //console.log(result);

  // var sortedArray = result.sort(function (a, b) {
  //   return b[1] - a[1];
  // });

  //console.log(sortedArray);

  // console.log(totalTime);

  // // var t = totalTime / 1000 / 60;

  // // console.log(t);

  // console.log(shortestTrack);

  // console.log(longestTrack);

  // console.log(shortestTime / 1000 / 60);

  // console.log(longestTime / 1000 / 60);

  // console.log(popPlaylist);

  // let max = 0;
  // let min = 10000000000;
  // let lowIndex;
  // let highIndex;
  // for (let i = 0; i < popPlaylist.length; i++) {
  //   if (popPlaylist[i] < min) {
  //     min = popPlaylist[i];
  //     lowIndex = i;
  //   }

  //   if (popPlaylist[i] > max) {
  //     max = popPlaylist[i];
  //     highIndex = i;
  //   }
  // }

  // console.log({ max, min, lowIndex, highIndex });

  console.log({ totalTime, shortestTrack, longestTrack });

  // Display changes

  const showTunes = () => {
    const playlists = document.getElementsByClassName("playlists")[0];

    playlists.style.display === "block"
      ? (playlists.style.display = "none")
      : (playlists.style.display = "block");
  };

  const x = document.getElementsByTagName("BODY")[0];
  x.style.background = "#1e1e1e";

  return (
    <>
      <div className="discs">
        <div className="neon-container">
          <h1 className="sign -cursive">Tinney's Island Discs 🏝</h1>
          <p className="sign">
            Welcome to the Mothership! <br />
            <br />
            Please click Stats to view a myriad of charts and info compiled from
            the combined playlists or click Tunes to see the individual
            playlists.
            <br />
            &#8964;
          </p>
        </div>
        <Stats
          popularArrObj={popularArrObj}
          unpopArrObj={unpopArrObj}
          arrObj={arrObj}
          data={data}
        />
        <h2>-----</h2>
        <h2 onClick={showTunes}>Tunes</h2>
        <div className="playlists">
          <Playlist
            name={keithList.owner.display_name}
            playlistName={keithList.name}
            playlistLink={keithList.external_urls.spotify}
            playlist={keith}
            visible={keithVisible}
            toggleVisible={showKeith}
          />
          <Playlist
            name={bootsList.owner.display_name}
            playlistName={bootsList.name}
            playlistLink={bootsList.external_urls.spotify}
            playlist={boots}
            visible={bootsVisible}
            toggleVisible={showBoots}
          />
          <Playlist
            name={odList.owner.display_name}
            playlistName={odList.name}
            playlistLink={odList.external_urls.spotify}
            playlist={od}
            visible={odVisible}
            toggleVisible={showOd}
          />
          <Playlist
            name={goonList.owner.display_name}
            playlistName={goonList.name}
            playlistLink={goonList.external_urls.spotify}
            playlist={goon}
            visible={goonVisible}
            toggleVisible={showGoon}
          />
          <Playlist
            name={tinmanList.owner.display_name}
            playlistName={tinmanList.name}
            playlistLink={tinmanList.external_urls.spotify}
            playlist={tinman}
            visible={tinmanVisible}
            toggleVisible={showTinman}
          />
          <Playlist
            name={evList.owner.display_name}
            playlistName={evList.name}
            playlistLink={evList.external_urls.spotify}
            playlist={ev}
            visible={evVisible}
            toggleVisible={showEv}
          />
          <Playlist
            name={davList.owner.display_name}
            playlistName={davList.name}
            playlistLink={davList.external_urls.spotify}
            playlist={dav}
            visible={davVisible}
            toggleVisible={showDav}
          />
          <Playlist
            name={caseyList.owner.display_name}
            playlistName={caseyList.name}
            playlistLink={caseyList.external_urls.spotify}
            playlist={casey}
            visible={caseyVisible}
            toggleVisible={showCasey}
          />
          <Playlist
            name={dooliList.owner.display_name}
            playlistName={dooliList.name}
            playlistLink={dooliList.external_urls.spotify}
            playlist={dooli}
            visible={dooliVisible}
            toggleVisible={showDooli}
          />
          <Playlist
            name={eamoList.owner.display_name}
            playlistName={eamoList.name}
            playlistLink={eamoList.external_urls.spotify}
            playlist={eamo}
            visible={eamoVisible}
            toggleVisible={showEamo}
          />
          <Playlist
            name={aidoList.owner.display_name}
            playlistName={aidoList.name}
            playlistLink={aidoList.external_urls.spotify}
            playlist={aido}
            visible={aidoVisible}
            toggleVisible={showAido}
          />
          <Playlist
            name={jtList.owner.display_name}
            playlistName={jtList.name}
            playlistLink={jtList.external_urls.spotify}
            playlist={jt}
            visible={jtVisible}
            toggleVisible={showJt}
          />
          <Playlist
            name={mattList.owner.display_name}
            playlistName={mattList.name}
            playlistLink={mattList.external_urls.spotify}
            playlist={matt}
            visible={mattVisible}
            toggleVisible={showMatt}
          />
          <PlaylistBonus
            name="the mcsharrynator69"
            playlistName={mmsList.name}
            playlistLink={mmsList.external_urls.spotify}
            playlist={mms}
            visible={mmsVisible}
            toggleVisible={showMms}
          />
        </div>
      </div>
      <footer>@david-mcgurrin 2021</footer>
    </>
  );
};

export default Discs;
