import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import McGurrin from "./components/McGurrin";
import Cullen from "./components/Cullen";
import McDonagh from "./components/McDonagh";
import McTiernan from "./components/McTiernan";
import Discs from "./components/Discs";
import Home from "./components/Home";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/discs">
          <Discs />
        </Route>
        <Route path="/family/mcdonagh">
          <McDonagh />
        </Route>
        <Route path="/family/mctiernan">
          <McTiernan />
        </Route>
        <Route path="/family/cullen">
          <Cullen />
        </Route>
        <Route path="/family/mcgurrin">
          <McGurrin />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}
