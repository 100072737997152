import React from "react";

const Playlist = (props) => {
  const updateVisibility = () => {
    props.toggleVisible();

    const playlist = document.getElementsByClassName(playlistName)[0];

    if (props.visible) {
      playlist.style.display = "none";
    } else {
      playlist.style.display = "block";
    }
  };

  const formatDate = (tune) => {
    const d = new Date(tune);
    return d.toDateString();
  };

  const playlistName = `playlist-${props.name}`;

  const noPreviewMessage = "Mate.. no preview :(";

  return (
    <div className="playlist-wrapper">
      <h3 className="playlist-creator" onClick={updateVisibility}>
        {props.name}
      </h3>
      <ul className={playlistName}>
        <li>
          <p className="playlist-link">
            <a
              href={props.playlistLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.playlistName}
            </a>
          </p>
          <p className="playlist-link-small">(Click to open)</p>
        </li>
        {props.playlist.map((song, i) => (
          <li key={song.track.id}>
            <div className="song-info">
              <img
                src={song.track.album.images[0].url}
                alt={song.track.artists[0].name}
                width="100"
              />
              <div className="song-info__text">
                <p className="author">{song.track.name}</p>
                <p>{song.track.artists[0].name}</p>
                <p>{formatDate(song.track.album.release_date)}</p>
              </div>
            </div>
            <p className="video-wrapper">
              {song.track.preview_url ? (
                <video controls name="media">
                  <source
                    src={song.track.preview_url}
                    type="audio/mpeg"
                  ></source>
                </video>
              ) : (
                <span>{noPreviewMessage}</span>
              )}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Playlist;
