import React from "react";
import FamilyTree from "../FamilyTree";

import familyTreeJson from "../../data/mcgurrin.json";

export default function McGurrin() {
  return (
    <FamilyTree
      fam="McGurrin"
      linkFam="Cullen"
      familyTreeJson={familyTreeJson}
    />
  );
}
