import React, { useState } from "react";
import Tree from "react-d3-tree";
import { useCenteredTree } from "../../helpers";
import "./FamilyTree.scss";

const containerStyles = {
  width: "80vw",
  height: "100vh",
};

export default function FamilyTree(props) {
  const [details, setDetails] = useState({});

  const renderNodeWithCustomEvents = ({
    nodeDatum,
    toggleNode,
    handleNodeClick,
  }) => (
    <>
      <g>
        {nodeDatum.attributes.link ? (
          <circle r="25" className="family-link-node" onClick={toggleNode} />
        ) : (
          <circle r="25" onClick={toggleNode} />
        )}
        <text
          fill="black"
          strokeWidth="1"
          x="2em"
          onClick={() => handleNodeClick(nodeDatum)}
        >
          {nodeDatum.name.split(" ")[0]}
        </text>
        <text
          fill="blue"
          stroke="#799dc3"
          strokeWidth="1"
          x="2em"
          y="20"
          onClick={() => handleNodeClick(nodeDatum)}
        >
          Details
        </text>
      </g>
    </>
  );

  const handleNodeClick = (nodeDatum) => {
    setDetails(nodeDatum);
  };

  const [translate, containerRef] = useCenteredTree();
  const sep = { siblings: 2, nonSiblings: 2 };

  const bioMarkup = (bio) => {
    return bio;
  };

  return (
    <div className="family-tree wrapper">
      <div className="details desktop">
        <div className="banner">
          <h2>Instructions</h2>
          <p>Click and drag to move. Scroll to zoom</p>
          <p>Click a blue coloured node to expand</p>
          <p>Click on a name or 'Details' to get more info</p>
          <p>Pink nodes contain links to other family branches</p>
          <p>
            Scroll down on the sidebar to view the info for the selected person
          </p>
        </div>
        <div className="deets">
          <h2>Details</h2>
          <h3>{details.name}</h3>
          {details.attributes && (
            <>
              <p>Born: {details.attributes.dob}</p>
              <p>Died: {details.attributes.dod}</p>
              <p>Address: {details.attributes.address}</p>
              <p>Occupation: {details.attributes.occupation}</p>
              {details.attributes.partner && (
                <>
                  <h3>{details.attributes.partner[0].name}</h3>
                  <p>Born: {details.attributes.partner[0].dob}</p>
                  <p>Died: {details.attributes.partner[0].dod}</p>
                  <p>Address: {details.attributes.partner[0].address}</p>
                  <p>Occupation: {details.attributes.partner[0].occupation}</p>
                </>
              )}
              {details.attributes.link && (
                <>
                  <p>
                    <b>
                      Click <a href={details.attributes.link}>here</a> to check
                      out the {props.linkFam} tree.
                    </b>
                  </p>
                </>
              )}
              <div className="info">
                {details.attributes.bio && (
                  <>
                    <h3>Info</h3>
                    <p>{bioMarkup(details.attributes.bio)}</p>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="tree" style={containerStyles} ref={containerRef}>
        <div className="family-name">
          <div>{props.fam} Family</div>
          {props.addedInfo ? (
            <p className="family-additional-info">
              More details on the {props.fam} family can be found{" "}
              <a href={props.addedInfo} target="blank">
                here
              </a>
              .
            </p>
          ) : null}
        </div>

        <Tree
          data={props.familyTreeJson}
          translate={translate}
          renderCustomNodeElement={(rd3tProps) =>
            renderNodeWithCustomEvents({ ...rd3tProps, handleNodeClick })
          }
          orientation="vertical"
          pathFunc="step"
          separation={sep}
          initialDepth={0}
          zoom={0.5}
        />

        <div className="details mobile">
          <div className="banner">
            <h2>Instructions</h2>
            <p>Click and drag to move. Scroll to zoom</p>
            <p>Click a coloured node to expand</p>
            <p>Click on a name or 'Details' to get more info</p>
          </div>
          <div className="deets">
            <h2>Details</h2>
            <h3>{details.name}</h3>
            {details.attributes && (
              <>
                <p>Born: {details.attributes.dob}</p>
                <p>Died: {details.attributes.dod}</p>
                <p>Address: {details.attributes.address}</p>
                <p>Occupation: {details.attributes.occupation}</p>
                {details.attributes.partner && (
                  <>
                    <h3>{details.attributes.partner[0].name}</h3>
                    <p>Born: {details.attributes.partner[0].dob}</p>
                    <p>Died: {details.attributes.partner[0].dod}</p>
                    <p>Address: {details.attributes.partner[0].address}</p>
                    <p>
                      Occupation: {details.attributes.partner[0].occupation}
                    </p>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
