import React from "react";
import FamilyTree from "../FamilyTree";

import familyTreeJson from "../../data/cullen.json";

const siteLink = "https://glenfarne-cullens.myshopify.com/";

export default function Cullen() {
  return (
    <FamilyTree
      fam="Cullen"
      linkFam="McGurrin"
      familyTreeJson={familyTreeJson}
      addedInfo={siteLink}
    />
  );
}
