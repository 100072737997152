import React from "react";

const PlaylistBonus = (props) => {
  const updateVisibility = () => {
    props.toggleVisible();
  };

  const formatDate = (tune) => {
    const d = new Date(tune);
    return d.toDateString();
  };

  const noPreviewMessage = "Mate.. no preview :(";

  return (
    <div className="playlist-wrapper">
      <h3 onClick={updateVisibility}>{props.name}</h3>
      {props.visible ? (
        <ul className="playlist-mms">
          <li>
            <p className="playlist-link">
              <a
                href={props.playlistLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.playlistName}
              </a>
            </p>
            <p className="playlist-link-small">(Click to open)</p>
          </li>
          {props.playlist.map((song, i) => (
            <li key={song.track.id}>
              {song.track.artists[0].name === "Alan Whole" ? (
                <>
                  <div className="song-info">
                    <img
                      src={song.track.album.images[0].url}
                      alt={song.track.artists[0].name}
                      width="100"
                    />
                    <div className="song-info__text">
                      <p className="author">{song.track.name}</p>
                      <p>{song.track.artists[0].name}</p>
                      <p>{formatDate(song.track.album.release_date)}</p>
                    </div>
                  </div>
                  <p className="video-wrapper">
                    {song.track.preview_url ? (
                      <video controls autoPlay={true} name="media">
                        <source
                          src={song.track.preview_url}
                          type="audio/mpeg"
                        ></source>
                      </video>
                    ) : (
                      <span>{noPreviewMessage}</span>
                    )}
                  </p>
                </>
              ) : (
                <>
                  <div className="song-info">
                    <img
                      src={song.track.album.images[0].url}
                      alt={song.track.artists[0].name}
                      width="100"
                    />
                    <div className="song-info__text">
                      <p className="author">{song.track.name}</p>
                      <p>{song.track.artists[0].name}</p>
                      <p>{formatDate(song.track.album.release_date)}</p>
                    </div>
                  </div>
                  <p className="video-wrapper">
                    {song.track.preview_url ? (
                      <video controls name="media">
                        <source
                          src={song.track.preview_url}
                          type="audio/mpeg"
                        ></source>
                      </video>
                    ) : (
                      <span>{noPreviewMessage}</span>
                    )}
                  </p>
                </>
              )}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default PlaylistBonus;
