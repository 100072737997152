import React from "react";
import FamilyTree from "../FamilyTree";

import familyTreeJson from "../../data/mctiernan.json";

export default function McTiernan() {
  return (
    <FamilyTree
      fam="McTiernan"
      linkFam="McDonagh"
      familyTreeJson={familyTreeJson}
    />
  );
}
